import Rellax from "rellax";

// Also can pass in optional settings block
var rellax = Rellax(".rellax", {
  speed: 1,
  center: true,
  round: true,
  vertical: true,
  horizontal: false,
});

window.addEventListener("scroll", function () {
  var scroll = window.scrollY;

  const navClass = {
    original: "sp-nav__desktop",
    targetClass: "sp-nav__desktop--fixed",
  };
  var desktopNav = document.getElementsByClassName(navClass.original)[0];

  if (scroll >= 1) {
    desktopNav.classList.add(navClass.targetClass);
  } else if (
    desktopNav.classList.contains(navClass.targetClass) &&
    scroll <= 0
  ) {
    desktopNav.classList.remove(navClass.targetClass);
  }
});

/* --------------------------------------------------------------------------*/
/*                              FAQ Rich Snippets                            */
/* --------------------------------------------------------------------------*/

document.addEventListener("DOMContentLoaded", function () {
  function setClass(els, className, fnName) {
    for (var i = 0; i < els.length; i++) {
      els[i].classList[fnName](className);
    }
  }

  if (document.getElementsByClassName("hd-content__faq--wrap").length >= 1) {
    var acc = document.getElementsByClassName("hd-content__faq--wrap");
    var panel = document.getElementsByClassName(
      "hd-content__faq--wrap__answer"
    );

    for (var i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function (e) {
        var setClasses = !this.classList.contains(
          "hd-content__faq--wrap__active"
        );
        setClass(acc, "hd-content__faq--wrap__active", "remove");
        setClass(panel, "show", "remove");

        if (setClasses) {
          this.classList.toggle("hd-content__faq--wrap__active");
          this.nextElementSibling.classList.toggle("show");
        }
      });
    }
  }
});
