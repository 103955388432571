function classToggler(btn, item, activeClass, activeBtn) {
  if (item.classList.contains(activeClass)) {
    item.classList.remove(activeClass);
    btn.classList.remove(activeBtn);
  } else {
    item.classList.add(activeClass);
    btn.classList.add(activeBtn);
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const els = {
    hamburger: ".hamburger",
    hamburgerActive: "is-active",
    menu: ".sp-nav__mobile--menu",
    menuActive: "sp-nav__mobile--menu__active",
    service: ".sp-nav__mobile--menu__service",
    serviceBtn: ".sp-nav__mobile--menu__service .btn__service",
    desktopServiceBtn: "desktopService",
    serviceMenu: ".sp-nav__desktop--service",
    mobileServiceBtn: "mobileServiceBtn",
    mobileServiceMenu: ".sp-nav__mobile--menu__service-wrap",
  };

  var hamburger = document.querySelector(els.hamburger);
  var menu = document.querySelector(els.menu);
  var service = document.querySelector(els.service);
  var serviceBtn = document.querySelector(els.serviceBtn);

  var serviceMenu = document.querySelector(els.serviceMenu);
  var serviceBtnDesktop = document.getElementById(els.desktopServiceBtn);
  var serviceBtnMobile = document.getElementById(els.mobileServiceBtn);
  var mobileServiceMenu = document.querySelector(els.mobileServiceMenu);

  hamburger?.addEventListener("click", (e) => {
    e.preventDefault();
    classToggler(hamburger, menu, els.menuActive, els.hamburgerActive);
    if (
      mobileServiceMenu.classList.contains(
        "sp-nav__mobile--menu__service-wrap--active"
      )
    ) {
      mobileServiceMenu.classList.remove(
        "sp-nav__mobile--menu__service-wrap--active"
      );
    }
  });

  serviceBtnDesktop?.addEventListener("click", (e) => {
    e.preventDefault();
    classToggler(
      serviceBtnDesktop,
      serviceMenu,
      "sp-nav__desktop--service__active",
      "btn__active"
    );
  });
  serviceBtnMobile?.addEventListener("click", (e) => {
    e.preventDefault();
    classToggler(
      serviceBtnMobile,
      mobileServiceMenu,
      "sp-nav__mobile--menu__service-wrap--active",
      "btn__active"
    );
  });
});

document.addEventListener("DOMContentLoaded", () => {
  var subcheck = document.getElementsByClassName("subcheck");
  for (var i = 0; i < subcheck.length; i++) {
    subcheck[i].addEventListener("click", function () {
      var subMenu = this.nextElementSibling;
      var subChildren = subMenu.children.length;
      var baseHeight = 38;

      if (this.classList.contains("subcheck__active")) {
        subMenu.classList.remove("mobile-menu__sub-menu--active");
        subMenu.style.height = "0px";
        this.classList.remove("subcheck__active");
      } else {
        subMenu.style.height = subChildren * baseHeight + "px";
        subMenu.classList.add("mobile-menu__sub-menu--active");
        this.classList.add("subcheck__active");
      }
    });
  }
});


// Mega nav

document.addEventListener('DOMContentLoaded', function() {

  var megaMenuItem = document.getElementsByClassName('solid-menu__item--mega');
  var megaMenu = document.getElementsByClassName('solid-menu__item--sub');
  var menuItem = document.getElementsByClassName('solid-menu__item--default');

  for(var i = 0; i < megaMenuItem.length; i++) {
      megaMenuItem[i].addEventListener('mouseenter', function() {
        for(var a = 0; a < megaMenuItem.length; a++) {
          if(megaMenuItem[a].classList.contains('solid-menu__item--mega__hover')) {
            megaMenuItem[a].classList.remove('solid-menu__item--mega__hover')
          }
        }
        this.classList.add('solid-menu__item--mega__hover');
      })
  }

  for(var i = 0; i < menuItem.length; i++) {
    menuItem[i].addEventListener('mouseenter', function() {
        for(var a = 0; a < megaMenuItem.length; a++) {
          if(megaMenuItem[a].classList.contains('solid-menu__item--mega__hover')) {
            megaMenuItem[a].classList.remove('solid-menu__item--mega__hover')
          }
        }
    })
}

  for(var i = 0; i < megaMenu.length; i++) {
    megaMenu[i].addEventListener('mouseleave', function() {
      this.parentElement.classList.remove('solid-menu__item--mega__hover');
    })
}

document.addEventListener("mouseleave", function(event){

  for(var a = 0; a < megaMenuItem.length; a++) {
    if(megaMenuItem[a].classList.contains('solid-menu__item--mega__hover')) {
      megaMenuItem[a].classList.remove('solid-menu__item--mega__hover')
    }
  }
});

})