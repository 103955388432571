import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Grid } from "@splidejs/splide-extension-grid";

document.addEventListener("DOMContentLoaded", function () {});

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("splide-cases").length >= 1) {
    var splide = new Splide(".splide-cases", {
      type: "fade",
      pagination: true,
      autoplay: true,
      duration: 5000,
      arrows: false,
    });
    splide.mount();
  }

  if (document.getElementsByClassName("splide-logos").length >= 1) {
    var logos = new Splide(".splide-logos", {
      type: "loop",
      perPage: 3,
      perMove: 1,
      drag: "free",
      focus: "center",
      arrows: false,
      pagination: true,
      autoplay: true,
      autoStart: true,
      autoScroll: {
        speed: 1,
      },
      breakpoints: {
        992: {
          perPage: 2,
        },
      },
    });
    logos.mount({ AutoScroll });
  }
});
