document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementsByClassName("gfield").length >= 1) {
    var inputs = document.querySelectorAll(".gfield .ginput_container input");
    var textarea = document.querySelectorAll(
      ".gfield .ginput_container textarea"
    );
    var select = document.querySelectorAll(".gfield .ginput_container select");

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].value !== "") {
        inputs[i].parentElement.parentElement.classList.add("gfield__active");
      } else {
        inputs[i].parentElement.parentElement.classList.remove(
          "gfield__active"
        );
      }

      inputs[i].addEventListener("input", function () {
        if (this.value !== "") {
          this.parentElement.parentElement.classList.add("gfield__active");
        } else {
          this.parentElement.parentElement.classList.remove("gfield__active");
        }
      });
    }

    for (var i = 0; i < textarea.length; i++) {
      if (textarea[i].value !== "") {
        textarea[i].parentElement.parentElement.classList.add("gfield__active");
      } else {
        textarea[i].parentElement.parentElement.classList.remove(
          "gfield__active"
        );
      }

      textarea[i].addEventListener("input", function () {
        if (this.value !== "") {
          this.parentElement.parentElement.classList.add("gfield__active");
        } else {
          this.parentElement.parentElement.classList.remove("gfield__active");
        }
      });
    }

    for (var i = 0; i < select.length; i++) {
      if (select[i].value !== "") {
        select[i].parentElement.parentElement.classList.add("gfield__active");
      } else {
        select[i].parentElement.parentElement.classList.remove(
          "gfield__active"
        );
      }

      select[i].addEventListener("focus", function () {
        if (this.value !== "") {
          this.parentElement.parentElement.classList.add("gfield__active");
        } else {
          this.parentElement.parentElement.classList.remove("gfield__active");
        }
      });
    }
  }
});
